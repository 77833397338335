import SalesAi from "@/common/assets/svg/salesai_logo.svg";
import { ReactComponent as WebhookImage } from "@/common/assets/svg/settings/webhooks-seeklogo 1.svg";
import { WebhookRequestCreateTypeEnum } from "@/services/generated";
import { ArrowsRightLeftIcon } from "@heroicons/react/16/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Dialog, DialogContent, DialogTitle, Slide, Tab, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import CreateWebhookTab from "./tabs/CreateWebhookTab";
import ManageWebhooksTab from "./tabs/ManageWebhooksTab";

export const schema = Yup.object({
  name: Yup.string().required(),
  description: Yup.string().required(),
  type: Yup.mixed<WebhookRequestCreateTypeEnum>().required("Type is required"),
}).required();

interface Prop {
  isOpen: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export enum TabValue {
  CREATE = "CREATE",
  MANAGE = "MANAGE",
}

export default function WebhookDialog(prop: Prop) {
  const open = prop.isOpen;
  const handleClose = prop.handleClose;
  const [tabValue, setTabValue] = React.useState<TabValue>(TabValue.CREATE);

  const onTabChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    if (!newValue) return;
    setTabValue(newValue);
    onTabChange?.(event, newValue);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#1A2135",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0.2px",
          }}
        >
          Add Integration
        </Typography>
        <Box
          onClick={handleClose}
          sx={{
            display: "flex",
            alignSelf: "center",
          }}
        >
          <CloseIcon
            sx={{
              cursor: "pointer",
              color: "#1A2135",
            }}
          />
        </Box>
      </DialogTitle>

      <Box
        sx={{
          background: "#E0E0E0",
          height: "1px",
          flexShrink: 0,
          alignSelf: "stretch",
        }}
      />
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "1200px",
        }}
      >
        <Images />

        <Title />

        <form>
          <FormProvider {...methods}>
            <TabContext value={tabValue}>
              <TabList
                onChange={handleTabChange}
                aria-label="Tabs"
                variant="fullWidth"
                sx={{
                  padding: "0px",
                  width: "50%",
                  minHeight: "42px",
                  marginBottom: "16px",
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#374667",
                  },
                }}
              >
                <Tab label="Create New Webhook" value={TabValue.CREATE} sx={{ textTransform: "capitalize" }} />
                <Tab label="Manage Webhooks" value={TabValue.MANAGE} sx={{ textTransform: "capitalize" }} />
              </TabList>
              <Box sx={{ minHeight: "250px" }}>
                <TabPanel value={TabValue.CREATE} sx={{ padding: "0px" }}>
                  <CreateWebhookTab onClose={handleClose} onSubmitCallback={() => setTabValue(TabValue.MANAGE)} />
                </TabPanel>
                <TabPanel value={TabValue.MANAGE} sx={{ padding: "0px" }}>
                  <ManageWebhooksTab onClose={handleClose} />
                </TabPanel>
              </Box>
            </TabContext>
          </FormProvider>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const Images = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      alignSelf: "stretch",
    }}
  >
    <img
      alt="SalesAI Logo"
      src={SalesAi}
      style={{
        display: "flex",
        width: "42px",
        height: "42px",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
    <ArrowsRightLeftIcon
      style={{
        display: "flex",
        width: "24px",
        height: "24px",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
    <WebhookImage
      style={{
        display: "flex",
        width: "42px",
        height: "42px",

        justifyContent: "center",
        alignItems: "center",
        gap: "6.4px",
        borderRadius: "3.048px",
      }}
    />
  </Box>
);

const Title = () => (
  <Typography
    sx={{
      color: "#1A2135",
      fontWeight: 400,
      fontSize: "18px",
      textAlign: "center",
      marginTop: "16px",
    }}
  >
    Adding integration with{" "}
    <span
      style={{
        fontWeight: 600,
      }}
    >
      Webhook
    </span>
  </Typography>
);
