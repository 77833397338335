import Button from "@/common/components/button/Button";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import toast from "@/lib/toast";
import { createWebhookUrl } from "@/redux/reducers/settings/company/crm/webhook/create";
import { useAppDispatch } from "@/redux/store";
import { WebhookRequestCreate, KnWebhookTypeEnum } from "@/services/generated";
import { Box, FormControl } from "@mui/material";
import { Controller, SubmitErrorHandler, SubmitHandler, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { schema } from "../WebhookDialog";

interface CreateWebhookTabProps {
  onClose: () => void;
  onSubmitCallback: () => void;
}

export default function CreateWebhookTab({ onClose, onSubmitCallback }: CreateWebhookTabProps) {
  const { control, handleSubmit, reset } = useFormContext();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<Yup.InferType<typeof schema>> = async (data: WebhookRequestCreate) => {
    const { name, description, type } = data;

    if (!name || !description || !type) {
      return;
    }

    dispatch(createWebhookUrl({ name, description, type })).then(() => {
      onSubmitCallback();
      reset();
    });
  };

  const onError: SubmitErrorHandler<Yup.InferType<typeof schema>> = (errors) => {
    toast.error("Please fill all the required fields");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Webhook Name" aria-required infoIcon />
            <Input {...field} error={fieldState.error?.message} placeholder="Name" />
          </FormControl>
        )}
      />

      <Controller
        name="type"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Webhook Type" aria-required infoIcon />
            <Dropdown
              {...field}
              error={fieldState.error?.message}
              placeholder="Select Webhook Type"
              options={Object.keys(KnWebhookTypeEnum).map((key) => ({
                label: key,
                value: KnWebhookTypeEnum[key as keyof typeof KnWebhookTypeEnum],
              }))}
              onChange={({ value }) => field.onChange(value)}
            />
          </FormControl>
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Lead Source Description" aria-required infoIcon />
            <Input {...field} error={fieldState.error?.message} placeholder="My Lead Source Description" />
          </FormControl>
        )}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Button size="sm" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button size="sm" onClick={handleSubmit(onSubmit, onError)}>
          Add Webhook
        </Button>
      </Box>
    </Box>
  );
}
