import { DatePicker } from "@/common/components/date-picker/DatePicker";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { lookupAgents } from "@/redux/reducers/ai-voice/listAgents";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { CallLogCallDirectionEnum, CallLogCallOutcomeEnum } from "@/services/generated";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { CallLogFiltersInput } from "..";

type CallLogFiltersProps = {
  filters: CallLogFiltersInput;
  setFilters: (filters: CallLogFiltersInput | ((prevFilters: CallLogFiltersInput) => CallLogFiltersInput)) => void;
  onExportCalls: () => void;
  loading?: boolean;
};

export const CALL_OUTCOME_SELECT_OPTIONS: { label: string; value: CallLogCallOutcomeEnum }[] = [
  { label: "Answered", value: CallLogCallOutcomeEnum.Answered },
  { label: "DNA (Did Not Answer)", value: CallLogCallOutcomeEnum.Dna },
  { label: "DNC (Do Not Call)", value: CallLogCallOutcomeEnum.Dnc },
  { label: "Booked a Meeting", value: CallLogCallOutcomeEnum.Booked },
  { label: "Transferred", value: CallLogCallOutcomeEnum.Transfer },
  { label: "Voicemail", value: CallLogCallOutcomeEnum.Voicemail },
];

export const CALL_DIRECTION_SELECT_OPTIONS: { label: string; value: CallLogCallDirectionEnum }[] = [
  { label: "Inbound", value: CallLogCallDirectionEnum.Inbound },
  { label: "Outbound", value: CallLogCallDirectionEnum.Outbound },
];
export default function CallLogFilters({ filters, setFilters, onExportCalls, loading }: CallLogFiltersProps) {
  const agentsList = useAppSelector(({ aiVoice }) => aiVoice.listAgents.data);

  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>("");

  const agentsOptions = useMemo(() => {
    return (agentsList || []).map((agent) => ({
      label: `${agent.agent_label} `,
      value: agent.phone_number,
    }));
  }, [agentsList]);

  useEffect(() => {
    dispatch(lookupAgents({}));
  }, [dispatch]);

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value: string) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          search: value,
        }));
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    debouncedSetFilters(search);

    return () => debouncedSetFilters.cancel();
  }, [debouncedSetFilters, search]);

  return (
    <form>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "end", paddingBottom: "16px" }}>
        <Button onClick={onExportCalls} disabled={loading}>
          Export
        </Button>
      </Box>
      <Box
        sx={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "3fr 1fr 1fr  1fr 1fr",
          gap: "16px",
        }}
      >
        <Searchbar
          placeholder="Type a phone number to search"
          value={search}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(value);
          }}
        />

        <Box sx={{ width: "100%" }}>
          <Dropdown
            label="Agent"
            placeholder="Agent"
            options={agentsOptions}
            onChange={(option) => {
              setFilters({ ...filters, agent: option.value });
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Dropdown
            label="Outcome"
            placeholder="Outcome"
            options={[...CALL_OUTCOME_SELECT_OPTIONS]}
            onChange={(option) => {
              setFilters({ ...filters, callOutcome: option.value });
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Dropdown
            label="Direction"
            placeholder="Direction"
            options={[...CALL_DIRECTION_SELECT_OPTIONS]}
            onChange={(option) => {
              setFilters({ ...filters, callDirection: option.value });
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <DatePicker
            range={true}
            value={[filters?.callEndedAtStart, filters?.callEndedAtEnd] as any}
            onChange={(formattedDates: any) => {
              if (formattedDates?.dateStart && formattedDates?.dateEnd) {
                setFilters((prev) => ({
                  ...prev,
                  callEndedAtStart: new Date(formattedDates?.dateStart),
                  callEndedAtEnd: new Date(formattedDates?.dateEnd),
                }));
              } else {
                setFilters((prev) => ({
                  ...prev,
                  callEndedAtStart: undefined,
                  callEndedAtEnd: undefined,
                }));
              }
            }}
          />
        </Box>
      </Box>
    </form>
  );
}
