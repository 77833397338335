import { combineReducers } from "@reduxjs/toolkit";
import getTeamsReducer from "./teams/slices/getTeams";
import impersonateReducer from "./users/slices/impersonation";

const teamsReducer = combineReducers({
  getTeams: getTeamsReducer,
  impersonateUser: impersonateReducer,
});

export default teamsReducer;
