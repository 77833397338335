import { Box, useTheme } from "@mui/material";
import Accordion from "../accordion/Accordion";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import { useEffect, useMemo } from "react";
import { getSequencesByContact } from "@/redux/reducers/sequences/slices/listSequences";
import { SequenceResponseStatusEnum } from "@/services/generated";
import { getDealsListByContactId } from "@/redux/reducers/deals/slices/listDeals";
import ProspectsAutocomplete from "../autocomplete/ProspectsAutocomplete";
import { FormProvider, useForm } from "react-hook-form";
import StatusChip from "@/common/components/status-chip";
import FormLabel from "@/common/components/form-label/FormLabel";
import { ProspectInfoBasic } from "@/common/components/prospect-info/ProspectInfoBasic";
import { dateFormatStr } from "@/common/functions/dateFormat";
import { ProspectSequenceActionButton } from "./ActionButton";
import Button from "../button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { showSeqEnrollDialog } from "@/redux/reducers/prospects/slices/listProspects";
import { TitleAndSubtitle } from "@/common/components/title-and-subtitle";

interface ProspectInfoTypeProps extends Prospect {
  isInbox?: boolean;
}

const ProspectInfo = ({ isInbox = false }: ProspectInfoTypeProps) => {
  const theme = useTheme();
  const currentProspect = useAppSelector((state) => state.prospects.listProspects.currentProspect);
  const prospects = useAppSelector(({ prospects }) => prospects.listProspects.prospects);
  const dispatch = useAppDispatch();
  const prospectSequences = useAppSelector(({ sequences }) => sequences.listSequences.prospectSequence);
  const prospectDealList = useAppSelector(({ deals }) => deals.listDeals.dealsByContactId);
  const methods = useForm();

  const deal = useMemo(() => {
    if (!prospectDealList) return null;
    if (prospectDealList.length === 0) return null;
    if (prospectDealList.length === 1) return prospectDealList[0];
    return null;
  }, [prospectDealList]);

  useEffect(() => {
    if (currentProspect?.id) {
      dispatch(getDealsListByContactId(currentProspect?.id));
      dispatch(getSequencesByContact(currentProspect?.id));
    }
  }, [currentProspect, dispatch]);

  if (!prospects) return null;

  const handleShowEnrollDialog = () => {
    dispatch(showSeqEnrollDialog());
  };

  return (
    <Box
      sx={({ palette }) => ({
        background: isInbox ? palette.midnight[20] : null,
        width: "100%",
        overflowY: "auto",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "inline-flex",
        gap: "24px",
        padding: isInbox ? "10px 10px" : "0px",
        height: "90vh",
        borderRadius: "8px",
      })}
    >
      {currentProspect?.firstName && (
        <Box sx={{ width: "100%" }}>
          <FormProvider {...methods}>
            <form>
              <FormLabel label="Prospect" />
              <ProspectsAutocomplete disabled={isInbox} />
            </form>
          </FormProvider>
        </Box>
      )}
      <Accordion summaryTitle="Prospect" defaultExpanded id="Prospect-info">
        <ProspectInfoBasic prospect={currentProspect || {}} />
      </Accordion>

      {deal && (
        <Accordion summaryTitle="Deal" defaultExpanded id="deal">
          <Box
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "16px",
              display: "flex",
              paddingLeft: "4px",
            }}
          >
            <TitleAndSubtitle title="Deal Name" subtitle={deal.name} alternativeColor={theme.palette.link.default} />
            {deal.amount > 0 && (
              <Box
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                {deal.amount > 0 && <TitleAndSubtitle title="Value" subtitle={deal.amount} />}
              </Box>
            )}
            <Box
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <TitleAndSubtitle title="Pipeline" subtitle={deal.pipelineId} />
              <TitleAndSubtitle title="Stage" subtitle={deal.pipelineStage.name} />
            </Box>
            {deal.lineItems.length > 0 && <TitleAndSubtitle title="product" subtitle={deal.lineItems[0]} />}
          </Box>
        </Accordion>
      )}

      <Accordion summaryTitle="Sequence" defaultExpanded id="Sequence-info">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          {prospectSequences?.map((sequence) => (
            <Box
              sx={{
                gap: "16px",
                flexDirection: "column",
                display: "flex",
                paddingLeft: "4px",
              }}
            >
              <Box
                style={{
                  alignSelf: "stretch",
                  justifyContent: "space-between",
                  alignItems: "center",
                  display: "inline-flex",
                }}
              >
                <StatusChip status={sequence.status || SequenceResponseStatusEnum.InProgress} type="sequence" />
                {sequence.id && sequence.status !== SequenceResponseStatusEnum.Canceled && (
                  <ProspectSequenceActionButton sequenceId={sequence.id} status={sequence.status} />
                )}
              </Box>

              <TitleAndSubtitle title="Sequence Name" subtitle={sequence.name} customWidth={isInbox ? null : "80%"} />

              {sequence.triggeredAt && (
                <TitleAndSubtitle title="Date Enrolled" subtitle={dateFormatStr(sequence.triggeredAt)} />
              )}

              <TitleAndSubtitle
                title="Progress of Sequence"
                subtitle={`${sequence.countStepsCompleted} out of ${sequence.countSteps}`}
              />
            </Box>
          ))}
        </Box>
      </Accordion>
      <Button
        sx={{ mx: "auto" }}
        onClick={handleShowEnrollDialog}
        size="sm"
        variant="secondary"
        leftIcon={<PlusIcon height={16} />}
      >
        Enroll Into Sequence
      </Button>
    </Box>
  );
};

export default ProspectInfo;
