import Button from "@/common/components/button";
import { impersonateUser } from "@/redux/reducers/admin/users/slices/impersonation";
import { useAppDispatch } from "@/redux/store";
import { EyeIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ActionProps {
  userId: string;
}

export default function Action({ userId }: ActionProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClick = async () => {
    await dispatch(impersonateUser(userId));

    navigate("/");

    navigate(0);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Button aria-label="impersonate" variant="secondary" rightIcon={<EyeIcon />} onClick={() => onClick()}>
        Impersonate
      </Button>
    </Box>
  );
}
