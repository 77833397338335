import toast from "@/lib/toast";
import { createSlice } from "@reduxjs/toolkit";
import { createWebhookUrl } from "./create";
import { deleteWebhookUrl } from "./delete";
import { searchWebhook } from "./search";
import { updateWebhookUrl } from "./update";

type WebhookType = {
  id: string;
  name: string;
  url: string;
  type: string;
  description: string;
};

type State = {
  webhookList: WebhookType[];
  page: number;
  pageNumber: number;
  loadingWebhookList: boolean;
  isCreatingWebhook: boolean;
};

const initialState: State = {
  webhookList: [],
  page: 1,
  pageNumber: 10,
  loadingWebhookList: false,
  isCreatingWebhook: false,
};

const webhookSlice = createSlice({
  name: "webhookSlice",
  initialState,
  extraReducers: (builder) => {
    // CREATE WEBHOOK
    builder.addCase(createWebhookUrl.pending, (state, { payload }) => {
      state.loadingWebhookList = true;
      state.isCreatingWebhook = true;
    });

    builder.addCase(createWebhookUrl.fulfilled, (state, { payload }) => {
      state.loadingWebhookList = false;
      state.isCreatingWebhook = false;
      toast.success("Webhook created successfully");
    });

    builder.addCase(createWebhookUrl.rejected, (state, { payload }) => {
      state.loadingWebhookList = false;
      state.isCreatingWebhook = false;
      toast.error("Webhook creation failed");
    });

    // UPDATE WEBHOOK
    builder.addCase(updateWebhookUrl.pending, (state, { payload }) => {
      state.loadingWebhookList = true;
    });

    builder.addCase(updateWebhookUrl.fulfilled, (state, { payload }) => {
      state.loadingWebhookList = false;

      state.webhookList = state.webhookList?.map((webhook) =>
        webhook.id === payload.id
          ? {
              ...webhook,
              ...(payload.name && { name: payload.name }),
              ...(payload.description && { description: payload.description }),
            }
          : webhook,
      );
      toast.success("Webhook updated successfully");
    });

    builder.addCase(updateWebhookUrl.rejected, (state, { payload }) => {
      state.loadingWebhookList = false;
      toast.error("Webhook update failed");
    });

    // DELETE WEBHOOK
    builder.addCase(deleteWebhookUrl.pending, (state, { payload }) => {
      state.loadingWebhookList = true;
    });

    builder.addCase(deleteWebhookUrl.fulfilled, (state, { payload }) => {
      state.loadingWebhookList = false;
      state.webhookList = state.webhookList?.filter((webhook) => webhook.id !== payload);
      toast.success("Webhook deleted successfully");
    });

    builder.addCase(deleteWebhookUrl.rejected, (state, { payload }) => {
      state.loadingWebhookList = false;
      toast.error("Webhook deletion failed");
    });

    // SEARCH WEBHOOK
    builder.addCase(searchWebhook.fulfilled, (state, { payload }) => {
      state.webhookList = payload.data.results as WebhookType[];
      state.page = payload.data.total || 0;
      state.loadingWebhookList = false;
    });

    builder.addCase(searchWebhook.rejected, (state, { payload }) => {
      state.webhookList = [];
      state.loadingWebhookList = false;
    });

    builder.addCase(searchWebhook.pending, (state, { payload }) => {
      state.loadingWebhookList = true;
    });
  },
  reducers: {},
});

export default webhookSlice.reducer;
