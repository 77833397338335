import { KnAiAgentRequestLanguageEnum } from "@/services/generated";

export const VOICE_LANGUAGE_OPTIONS: {
  value: KnAiAgentRequestLanguageEnum;
  tag: string;
  label: string;
}[] = [
  { value: KnAiAgentRequestLanguageEnum.EnUs, tag: "ENGLISH", label: "English" },
  { value: KnAiAgentRequestLanguageEnum.EnAu, tag: "ENGLISH_AUSTRALIA", label: "English (Australia)" },
  { value: KnAiAgentRequestLanguageEnum.EnIn, tag: "ENGLISH_INDIA", label: "English (India)" },
  { value: KnAiAgentRequestLanguageEnum.EnGb, tag: "ENGLISH_UK", label: "English (UK)" },
  { value: KnAiAgentRequestLanguageEnum.Fr, tag: "FRENCH", label: "French" },
  { value: KnAiAgentRequestLanguageEnum.FrCa, tag: "FRENCH_CANADA", label: "French (Canada)" },
  { value: KnAiAgentRequestLanguageEnum.Nl, tag: "DUTCH", label: "Dutch" },
  { value: KnAiAgentRequestLanguageEnum.It, tag: "ITALIAN", label: "Italian" },
  { value: KnAiAgentRequestLanguageEnum.Zh, tag: "CHINESE_MANDARIN", label: "Chinese (Mandarin)" },
  { value: KnAiAgentRequestLanguageEnum.Pt, tag: "PORTUGUESE", label: "Portuguese" },
  { value: KnAiAgentRequestLanguageEnum.PtBr, tag: "PORTUGUESE_BRAZIL", label: "Portuguese (Brazil)" },
  { value: KnAiAgentRequestLanguageEnum.Es, tag: "SPANISH", label: "Spanish" },
  { value: KnAiAgentRequestLanguageEnum.Es419, tag: "SPANISH_LATIN_AMERICA", label: "Spanish (Latin America)" },
  { value: KnAiAgentRequestLanguageEnum.Hi, tag: "HINDI", label: "Hindi" },
  { value: KnAiAgentRequestLanguageEnum.HiLatn, tag: "HINDI_LATIN_SCRIPT", label: "Hindi (Latin Script)" },
];
