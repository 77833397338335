import { stopImpersonation } from "@/redux/reducers/admin/users/slices/impersonation";
import { useAppDispatch } from "@/redux/store";
import { Snackbar } from "@mui/material";
import SnackbarContent from "@mui/material/SnackbarContent";
import { EyeOffOutline } from "heroicons-react";
import Button from "../button";
import { useRoles } from "../roles/useRoles";
import { useNavigate } from "react-router-dom";

interface ActionProps {
  onClick: () => void;
}

const action = ({ onClick }: ActionProps) => {
  return (
    <Button
      size="sm"
      sx={{
        background: "black",
        color: "white",
        "&:hover": {
          backgroundColor: "white !important",
          color: "black !important",
        },
      }}
      rightIcon={<EyeOffOutline />}
      onClick={onClick}
    >
      Stop Impersonating
    </Button>
  );
};

export default function LongTextSnackbar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    auth0: { user },
  } = useRoles();

  const stopImpersonationAction = async () => {
    await dispatch(stopImpersonation());

    navigate("/");

    navigate(0);
  };

  return (
    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!!user?.is_impersonation}>
      <SnackbarContent
        sx={{ background: "#ffe49a", color: "#1a2135" }}
        message={`You are impersonating user ${user?.impersonated_user?.name} (${user?.impersonated_user?.email})`}
        action={action({ onClick: () => stopImpersonationAction() })}
      />
    </Snackbar>
  );
}
