import EditProspectForm from "@/pages/prospects/edit/EditProspectForm";
import { closeEditDrawer } from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Drawer from "@mui/material/Drawer";

interface EditProspectDrawerProps {
  onSubmit: () => void;
}

export default function EditProspectDrawer(props: EditProspectDrawerProps) {
  const { editDrawerIsOpen } = useAppSelector(({ prospects }) => prospects.listProspects);
  const dispatch = useAppDispatch();

  return (
    <Drawer
      open={editDrawerIsOpen}
      onClose={() => {
        dispatch(closeEditDrawer());
      }}
      anchor="right"
    >
      <EditProspectForm onSubmitCallBack={props.onSubmit} />
    </Drawer>
  );
}
