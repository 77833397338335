import Services from "@/services";
import { KnLookupRequest } from "@/services/generated";
import { Team, TeamUser } from "@/types/admin";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  teams: Team[];
  total: number;
  loading: boolean;
  error?: string;

  viewDrawerOpen: boolean;
  selectedTeam?: Team;

  selectedTeamUsers: TeamUser[];
  totalUsers: number;
  loadingUsers: boolean;
  errorUsers?: string;

  teamTotalMinutes: number;
  teamMinutesRemaining: number;
  loadingMinutes?: boolean;
  errorMinutes?: string;
};

const initialState: State = {
  total: 0,
  teams: [],
  loading: false,

  viewDrawerOpen: false,

  selectedTeamUsers: [],
  totalUsers: 0,
  loadingUsers: false,

  teamTotalMinutes: 0,
  teamMinutesRemaining: 0,
  loadingMinutes: false,
};

export const getTeams = createAsyncThunk("admin/getTeams", async (filters: KnLookupRequest, { rejectWithValue }) => {
  try {
    const response = await Services.Admin.getTeams(filters);

    return response;
  } catch (error) {
    rejectWithValue(error.message);
  }
});

export const getTeamUsers = createAsyncThunk(
  "admin/getTeamUsers",
  async ({ teamId, filters }: { teamId: string; filters: KnLookupRequest }, { rejectWithValue }) => {
    try {
      const response = await Services.Admin.getUsers(teamId, filters);

      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  },
);

export const getTeamMinutes = createAsyncThunk("admin/getTeamMinutes", async (teamId: string, { rejectWithValue }) => {
  try {
    const response = await Services.Billing.billingMinutes(teamId);

    return response;
  } catch (error) {
    rejectWithValue(error.message);
  }
});

const getTeamsSlice = createSlice({
  name: "getTeams",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTeams.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTeams.fulfilled, (state, { payload }) => {
      state.loading = false;

      if (payload?.data.results) {
        state.teams = payload.data.results.map((team) => ({ ...team, id: team.id ?? "" }));
      } else {
        state.teams = [];
      }

      state.total = payload?.data.total ?? 0;
    });

    builder.addCase(getTeams.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(getTeamUsers.pending, (state) => {
      state.loadingUsers = true;
    });

    builder.addCase(getTeamUsers.fulfilled, (state, { payload }) => {
      state.loadingUsers = false;

      state.selectedTeamUsers = (payload?.data.results as TeamUser[]) ?? [];

      state.totalUsers = payload?.data.total ?? 0;
    });

    builder.addCase(getTeamUsers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(getTeamMinutes.pending, (state) => {
      state.loadingMinutes = true;
    });

    builder.addCase(getTeamMinutes.fulfilled, (state, { payload }) => {
      state.loadingMinutes = false;

      state.teamTotalMinutes = payload?.data.minutesTotal ?? 0;
      state.teamMinutesRemaining = payload?.data.minutesRemaining ?? 0;
    });

    builder.addCase(getTeamMinutes.rejected, (state, { payload }) => {
      state.loadingMinutes = false;
      state.errorMinutes = payload as string;
    });
  },
  reducers: {
    openViewDrawer: (state) => {
      state.viewDrawerOpen = true;
    },
    closeViewDrawer: (state) => {
      state.viewDrawerOpen = false;
    },
    setSelectedTeam: (state, { payload }) => {
      state.selectedTeam = payload;
    },
  },
});

export const { openViewDrawer, closeViewDrawer, setSelectedTeam } = getTeamsSlice.actions;

export default getTeamsSlice.reducer;
