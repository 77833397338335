import Services from "@/services";
import { SearchWebHookTypeEnum } from "@/services/generated";
import { createAsyncThunk } from "@reduxjs/toolkit";

type Params = {
  type?: Array<SearchWebHookTypeEnum>;
  name?: string;
  pageNumber?: number;
  pageSize?: number;
};

export const searchWebhook = createAsyncThunk("settings/searchWebhook", async (params: Params, { rejectWithValue }) => {
  try {
    const { type, name, pageNumber, pageSize } = params;
    const response = await Services.Webhooks.searchWebHook(name || undefined, type, pageNumber, pageSize);

    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
