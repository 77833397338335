import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { usePagination } from "@/common/components/pagination/usePagination";
import Searchbar from "@/common/components/searchbar/Searchbar";
import TsTable from "@/common/components/ts-table";
import { getTeams, openViewDrawer, setSelectedTeam } from "@/redux/reducers/admin/teams/slices/getTeams";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Team } from "@/types/admin";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import { Box, Typography } from "@mui/material";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { TeamsColumns } from "./columns";
import ViewTeamDrawer from "./view";

export default function TeamsTable() {
  const { teams, total, loading } = useAppSelector((selector) => selector.teams.getTeams);

  const { onPaginationChange, pagination } = usePagination();

  const [nameFilter, setNameFilter] = useState("");
  const [debouncedNameFilter, setDebouncedNameFilter] = useState("");

  const handleCellClick = async (team: Team) => {
    dispatch(setSelectedTeam(team));

    dispatch(openViewDrawer());
  };

  const dispatch = useAppDispatch();

  const setDebounced = useMemo(
    () =>
      debounce((value) => {
        setDebouncedNameFilter(value);
      }, 500),
    [],
  );

  useEffect(() => {
    dispatch(
      getTeams({
        pageSize: pagination.pageSize,
        page: pagination.pageIndex,
        filters: [
          {
            options: [{ operator: "equal", values: [debouncedNameFilter] }],
            property: "name",
          },
        ],
      }),
    );
  }, [dispatch, debouncedNameFilter, pagination.pageIndex, pagination.pageSize]);

  const table = useReactTable<Team>({
    data: teams,
    columns: TeamsColumns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: total,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Teams
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
          }}
        >
          Manage your team and users.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        <Box sx={{ gridColumn: "1 / 2" }}>
          <Searchbar
            value={nameFilter || ""}
            onChange={(event) => {
              setDebounced(event.target.value);
              setNameFilter(event.target.value);
            }}
            placeholder="Search by Name"
          />
        </Box>
      </Box>
      <FullLoadingOverlay loading={loading} />
      {teams && (
        <>
          <TsTable
            table={table}
            handleCellClick={(_, team) => {
              if (team) handleCellClick(team);
            }}
            notFoundProps={{
              title: teams.length === 0 && nameFilter ? "No teams found that match your criteria" : "No teams found",
              description:
                teams.length === 0 && nameFilter ? (
                  <span>Try a different search parameter.</span>
                ) : (
                  <span>No teams found.</span>
                ),
              Icon: SquaresPlusIcon,
            }}
          />
          <ViewTeamDrawer />
        </>
      )}
    </Box>
  );
}
