import toast from "@/lib/toast";
import { WebhookRequestCreateTypeEnum } from "@/services/generated";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Services from "@/services";

type Params = {
  name: string;
  description: string;
  type: WebhookRequestCreateTypeEnum;
};

export const createWebhookUrl = createAsyncThunk("settings/createWebhookUrl", async (params: Params) => {
  try {
    const response = await Services.Webhooks.createWebHookURL({
      name: params.name,
      description: params.description,
      type: params.type,
    });

    return response;
  } catch (error) {
    toast.error("Webhook creation failed", error.response.data);
    return error;
  }
});
