import PageHeader from "@/common/components/page-header/PageHeader";
import { Box } from "@mui/material";
import TeamsTable from "./teams";

export default function AdminPage() {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <PageHeader title="Admin" />
      <TeamsTable />
    </Box>
  );
}
