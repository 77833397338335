export const RegularRoles = [
  {
    roleName: "Sales Manager",
    roleCode: "SALES_MANAGER",
    roleDescription: "Main SalesAi admin. Add user, set global settings.",
  },
  {
    roleName: "Sales Representative",
    roleCode: "SALES_REPRESENTATIVE",
    roleDescription: "Sales administrative user: Can edit personal settings",
  },
];

export const AdminRole = {
  roleName: "Account Administrator",
  roleCode: "ADMIN",
  roleDescription: "Account administrator: Can edit team settings",
};

export const SuperadminRole = {
  roleName: "Super Administrator",
  roleCode: "SUPERADMIN",
  roleDescription: "Account super administrator: Can modify account administrators",
};

export const AdminRoles = [AdminRole, SuperadminRole];

export const RolesMap = {
  SALES_MANAGER: "Sales Manager",
  SALES_REPRESENTATIVE: "Sales Representative",
  ADMIN: "Account Administrator",
  SUPERADMIN: "Super Administrator",
};

export const Roles = [...RegularRoles, ...AdminRoles];
