import DrawerLayout from "@/layouts/DrawerLayout";
import { closeViewDrawer } from "@/redux/reducers/admin/teams/slices/getTeams";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import Drawer from "@mui/material/Drawer";
import { FormProvider, useForm } from "react-hook-form";
import TeamInfo from "./TeamInfo";

export default function ViewTeamDrawer() {
  const dispatch = useAppDispatch();
  const { viewDrawerOpen, selectedTeam } = useAppSelector(({ teams }) => teams.getTeams);
  const methods = useForm();

  const toggleDrawer = () => dispatch(closeViewDrawer());

  return (
    <FormProvider {...methods}>
      <Drawer open={viewDrawerOpen} onClose={() => dispatch(closeViewDrawer())} anchor="right">
        <DrawerLayout customWidth="1022px" title={selectedTeam?.name ?? ""} closeDrawer={toggleDrawer}>
          <TeamInfo teamId={selectedTeam?.id ?? ""} />
        </DrawerLayout>
      </Drawer>
    </FormProvider>
  );
}
