import TableCell from "@/common/components/table-cell";
import TableHeader from "@/common/components/table-header";
import { RolesMap } from "@/common/constants/roles";
import { TeamUser } from "@/types/admin";
import { createColumnHelper } from "@tanstack/react-table";
import Action from "./action";

const columnHelper = createColumnHelper<TeamUser>();

export const TeamUsersColumns = [
  columnHelper.accessor("firstName", {
    header: () => <TableHeader>User Full Name</TableHeader>,
    cell: (info) => {
      const row = info.row;
      return <TableCell>{`${info.getValue()} ${row.original.lastName}`}</TableCell>;
    },
  }),

  columnHelper.accessor("email", {
    header: () => <TableHeader>Email</TableHeader>,
    cell: (info) => <TableCell>{info.getValue()}</TableCell>,
  }),

  columnHelper.accessor("userRole", {
    header: () => <TableHeader>Role</TableHeader>,
    cell: (info) => <TableCell>{RolesMap[info.getValue() ?? "SALES_REPRESENTATIVE"]}</TableCell>,
  }),

  columnHelper.accessor("user_id", {
    header: () => <TableHeader>Impersonate User</TableHeader>,
    cell: (info) => <Action userId={info.getValue() ?? ""} />,
  }),
];
