import { createAsyncThunk } from "@reduxjs/toolkit";
import { KnBqLookupResponseKnCallMessage, KnCallMessage } from "@/services/generated";
import axios, { AxiosResponse } from "axios";
import Services from "@/services";

interface CallSummaryProps {
  id: string;
  pageNumber?: number;
  pageSize?: number;
}

export const getCallSummary = createAsyncThunk(
  "aiVoice/getCallSummary",
  async (
    { id, pageNumber, pageSize }: CallSummaryProps,
    { rejectWithValue, dispatch },
  ): Promise<AxiosResponse<KnBqLookupResponseKnCallMessage>> => {
    try {
      return await Services.AiVoice.getCallDetails(id, pageNumber, pageSize);
    } catch (error: any) {
      return error;
    }
  },
);
