import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "@/lib/toast";
import Services from "@/services";

type Params = {
  id: string;
};

export const deleteWebhookUrl = createAsyncThunk("settings/deleteWebhookUrl", async (params: Params) => {
  try {
    const id = params.id;

    await Services.Webhooks.deleteWebHook(id);

    toast.success("Webhook deleted successfully");

    return id;
  } catch (error) {
    return error;
  }
});
