import {
  Configuration,
  SequencesApi,
  SequenceStepTemplatesApi,
  SequenceMessagesApi,
  SequenceTemplatesApi,
  DripTemplateControllerApi,
  TasksApi,
  UsersApi,
  TeamsApi,
  DealsApi,
  ProspectContactApi,
  ProspectCompanyApi,
  TriggersApi,
  PipelinesApi,
  MediaControllerApi,
  CrmApi,
  ProductsApi,
  ProspectFinderApi,
  StagesApi,
  TriggerActionsApi,
  ReportApi,
  AiApi,
  CalendarOauthApi,
  OutreachApi,
  LeadFlowApi,
  AiVoiceApi,
  ConsentControllerApi,
  OutreachOauthApi,
  InviteApi,
  BillingApi,
  DataImportApi,
  DataImportCompanyApi,
  DataImportContactApi,
  DataImportProductApi,
  TeamUserRolesApi,
  Ap2ServiceApi,
  FaqApi,
  LeadSourceControllerApi,
  AdminTeamControllerApi,
  WebhookAdminControllerApi,
} from "./generated";
import { getAuth0AccessToken } from "./auth";
import config from "@/common/constants/config";

const gatewayConfig = new Configuration({
  accessToken: getAuth0AccessToken,
  basePath: config.urlApiGateway,
});

const Services = {
  Admin: new AdminTeamControllerApi(gatewayConfig),
  Ai: new AiApi(gatewayConfig),
  AiVoice: new AiVoiceApi(gatewayConfig),
  Faq: new FaqApi(gatewayConfig),
  Users: new UsersApi(gatewayConfig),
  Tasks: new TasksApi(gatewayConfig),
  Reports: new ReportApi(gatewayConfig),
  Sequences: new SequencesApi(gatewayConfig),
  SequenceTemplates: new SequenceTemplatesApi(gatewayConfig),
  SequenceMessages: new SequenceMessagesApi(gatewayConfig),
  SequenceStepTemplates: new SequenceStepTemplatesApi(gatewayConfig),
  DripTemplateController: new DripTemplateControllerApi(gatewayConfig),
  Teams: new TeamsApi(gatewayConfig),
  Deals: new DealsApi(gatewayConfig),
  Prospects: new ProspectContactApi(gatewayConfig),
  Companies: new ProspectCompanyApi(gatewayConfig),
  Pipelines: new PipelinesApi(gatewayConfig),
  Triggers: new TriggersApi(gatewayConfig),
  MediaController: new MediaControllerApi(gatewayConfig),
  Crm: new CrmApi(gatewayConfig),
  Calendar: new CalendarOauthApi(gatewayConfig),
  Products: new ProductsApi(gatewayConfig),
  // ProspectInbound: new ProspectInboundApi(gatewayConfig),
  ProspectFinder: new ProspectFinderApi(gatewayConfig),
  Stages: new StagesApi(gatewayConfig),
  TriggerActions: new TriggerActionsApi(gatewayConfig),
  Inbox: new OutreachApi(gatewayConfig),
  OutreachOauth: new OutreachOauthApi(gatewayConfig),
  LeadFlow: new LeadFlowApi(gatewayConfig),
  Invite: new InviteApi(gatewayConfig),
  Billing: new BillingApi(gatewayConfig),
  Consent: new ConsentControllerApi(gatewayConfig),
  DataImport: new DataImportApi(gatewayConfig),
  DataImportCompany: new DataImportCompanyApi(gatewayConfig),
  DataImportContact: new DataImportContactApi(gatewayConfig),
  DataImportProduct: new DataImportProductApi(gatewayConfig),
  TeamUserRoles: new TeamUserRolesApi(gatewayConfig),
  A2p: new Ap2ServiceApi(gatewayConfig),
  LeadSource: new LeadSourceControllerApi(gatewayConfig),
  Webhooks: new WebhookAdminControllerApi(gatewayConfig),
};

export default Services;
