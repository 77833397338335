import Button from "@/common/components/button/Button";
import Dropdown from "@/common/components/dropdown";
import Input from "@/common/components/input";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { usePagination } from "@/common/components/pagination/usePagination";
import Searchbar from "@/common/components/searchbar/Searchbar";
import TsTable from "@/common/components/ts-table";
import toast from "@/lib/toast";
import { deleteWebhookUrl } from "@/redux/reducers/settings/company/crm/webhook/delete";
import { searchWebhook } from "@/redux/reducers/settings/company/crm/webhook/search";
import { updateWebhookUrl } from "@/redux/reducers/settings/company/crm/webhook/update";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { SearchWebHookTypeEnum } from "@/services/generated";
import { ClipboardDocumentIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Check, X } from "heroicons-react";
import React, { useEffect, useMemo } from "react";

type WebhookType = {
  id: string;
  name: string;
  url: string;
  type: string;
  description: string;
};

interface ManageWebhooksTabProps {
  onClose: () => void;
}

export default function ManageWebhooksTab({ onClose }: ManageWebhooksTabProps) {
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [editableId, setEditableId] = React.useState<string | null>(null);
  const dispatch = useAppDispatch();
  const webhookUrls = useAppSelector((state) => state.settings.webhookReducer);
  const { onPaginationChange, pagination } = usePagination();

  const descriptionRef = React.useRef<HTMLInputElement>(null);
  const nameRef = React.useRef<HTMLInputElement>(null);

  const handleSetEditableId = (data: WebhookType) => {
    setEditableId(data.id);
  };

  const deleteWebhook = async (id: string) => {
    dispatch(deleteWebhookUrl({ id }));
  };

  const menuOptions =
    Object.keys(SearchWebHookTypeEnum).map((value: keyof typeof SearchWebHookTypeEnum) => ({
      label: value,
      value: SearchWebHookTypeEnum[value],
    })) || [];

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(searchWebhook({ name: searchValue, pageNumber: pagination.pageIndex, pageSize: pagination.pageSize }));
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue, pagination.pageIndex, pagination.pageSize, dispatch]);

  const handleUpdateRow = async (data: WebhookType) => {
    try {
      const updatedData: { id: string; name?: string; description?: string } = {
        id: data.id,
      };

      if (nameRef.current?.value && nameRef.current.value !== data.name) {
        updatedData.name = nameRef.current.value;
      }

      if (descriptionRef.current?.value && descriptionRef.current.value !== data.description) {
        updatedData.description = descriptionRef.current.value;
      }

      if (!updatedData.name && !updatedData.description) {
        toast.error("Either name or description must be provided.");
        return;
      }

      dispatch(updateWebhookUrl(updatedData));

      setEditableId(null);
    } catch (error) {
      toast.error("Webhook update failed", error.response?.data);
    }
  };

  const columnHelper = createColumnHelper<WebhookType>();
  const columns = useMemo<ColumnDef<WebhookType, any>[]>(
    () => [
      columnHelper.accessor("name", {
        header: () => <Typography variant="body3">WEBHOOK NAME</Typography>,
        cell: (info) => (
          <>
            {editableId === info.row.original.id ? (
              <Input
                ref={nameRef}
                placeholder={info?.getValue()}
                value={nameRef.current?.value}
                sx={{ width: "150px" }}
                onChange={(e) => {
                  if (nameRef.current) {
                    nameRef.current.value = e.target.value;
                  }
                }}
              />
            ) : (
              <Typography variant="body2">{info?.getValue()}</Typography>
            )}
          </>
        ),
      }),

      columnHelper.accessor("url", {
        header: () => <Typography variant="body3">WEBHOOK URL</Typography>,
        cell: (info) => (
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "40ch", // 40 characters
              }}
            >
              {info.getValue()}
            </Typography>
            <Box>
              <ClipboardDocumentIcon
                width="20"
                height="20"
                style={{ cursor: "pointer" }}
                onClick={() => navigator.clipboard.writeText(info.getValue())}
              />
            </Box>
          </Box>
        ),
      }),

      columnHelper.accessor("type", {
        header: () => <Typography variant="body3">WEBHOOK TYPE</Typography>,
        cell: (info) => {
          return (
            <>
              <Typography variant="body2">{info.getValue()}</Typography>
            </>
          );
        },
      }),

      columnHelper.accessor("description", {
        header: () => <Typography variant="body3">LEAD SOURCE DESCRIPTION</Typography>,
        cell: (info) => (
          <>
            {editableId === info.row.original.id ? (
              <Input
                ref={descriptionRef}
                placeholder={info.getValue()}
                sx={{ width: "200px" }}
                value={descriptionRef.current?.value}
                onChange={(e) => {
                  if (descriptionRef.current) {
                    descriptionRef.current.value = e.target.value;
                  }
                }}
              />
            ) : (
              <Typography variant="body2">{info.getValue()}</Typography>
            )}
          </>
        ),
      }),

      columnHelper.display({
        id: "actions",
        header: () => <Typography variant="body3">ACTIONS</Typography>,
        cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              gap: "8px",
            }}
          >
            {editableId !== row.original.id ? (
              <PencilSquareIcon
                width="24"
                height="24"
                style={{
                  color: "#1a2135",
                  cursor: "pointer",
                }}
                onClick={() => handleSetEditableId(row.original)}
              />
            ) : (
              <Box sx={{ display: "flex", gap: "8px" }}>
                <Check
                  width="24"
                  height="24"
                  style={{ color: "#05cf74", cursor: "pointer" }}
                  onClick={() => handleUpdateRow(row.original)}
                />
                <X
                  width="24"
                  height="24"
                  style={{ color: "#fb2d67", cursor: "pointer" }}
                  onClick={() => setEditableId(null)}
                />
              </Box>
            )}
            <TrashIcon
              width="24"
              height="24"
              style={{
                color: "#EF4444",
                cursor: "pointer",
              }}
              onClick={() => deleteWebhook(row.original.id)}
            />
          </Box>
        ),
      }),
    ],
    [editableId],
  );

  const table = useReactTable<WebhookType>({
    data: webhookUrls.webhookList,
    pageCount: webhookUrls.page,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
    manualPagination: true,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          justifyContent: "space-between",
        }}
      >
        <Searchbar
          sx={{ width: "300px" }}
          placeholder="Search for a Webhook"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <Box sx={{ minWidth: "180px" }}>
          <Dropdown
            placeholder="All"
            options={menuOptions}
            onChange={({ value }: { value: SearchWebHookTypeEnum }) => {
              dispatch(
                searchWebhook({
                  name: searchValue,
                  type: [value],
                }),
              );
            }}
          />
        </Box>
      </Box>
      {webhookUrls.loadingWebhookList ? <LoadingSpinner /> : <TsTable table={table} />}
      <Button
        size="sm"
        variant="secondary"
        onClick={onClose}
        sx={{
          alignSelf: "flex-end",
        }}
      >
        Cancel
      </Button>
    </Box>
  );
}
