import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  //
};

const initialState: State = {};

export const impersonateUser = createAsyncThunk("admin/impersonate", async (userId: string, { rejectWithValue }) => {
  try {
    const response = await Services.Admin.impersonateUser(userId);

    return response;
  } catch (error) {
    rejectWithValue(error.message);
  }
});

export const stopImpersonation = createAsyncThunk("admin/stopImpersonation", async (_, { rejectWithValue }) => {
  try {
    const response = await Services.Admin.stopImpersonation();

    return response;
  } catch (error) {
    rejectWithValue(error.message);
  }
});

const impersonationSlice = createSlice({
  name: "impersonateUser",
  initialState,
  reducers: {},
});

export const {} = impersonationSlice.actions;

export default impersonationSlice.reducer;
