import { Box, Typography, useTheme } from "@mui/material";
import { SequenceActionTypes } from "./SequenceActionTypes";
import { SequenceActionContent } from "./SequenceActionContent";
import { SequenceOptions } from "./SequenceOptions";
import { useEffect, useMemo, useState } from "react";
import { StepTemplate, StepTemplateType } from "@/types/sequence";
import { lookupAgents } from "@/redux/reducers/ai-voice/listAgents";
import { KnAiAgentCallDirectionEnum } from "@/services/generated";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { DelayForm } from "./ActionSetDelay";

export interface SequenceActionProps extends StepTemplate {
  index: number;
  canClose?: boolean;
  canUpdateSequence?: boolean;

  onSelectActionType?: (actionType: StepTemplateType) => void;
  onUpdatedAction?: () => void;
  onMoveUp?: (id: string) => void;
  onMoveDown?: (id: string) => void;
  onDuplicateAction?: (id: string) => void;
  onSetDelay?: (id: string, delay: DelayForm) => void;
  onRemoveAction?: (id: string) => void;
  onRemoveDraftAction?: (index: number) => void;
  aiAgentId?: string;
  hiddenTypes?: StepTemplateType[];
}

export default function SequenceAction({
  id = "",
  index,
  delayDays = 0,
  delayHours = 0,
  delayMinutes = 0,
  delaySeconds = 0,
  actionType,
  canClose = false,
  canUpdateSequence,
  messageTemplate = "",
  subjectTemplate = "",
  aiAgentId,
  onSelectActionType,
  onUpdatedAction,
  onMoveUp,
  onMoveDown,
  onDuplicateAction,
  onSetDelay,
  onRemoveDraftAction,
  onRemoveAction,
  hiddenTypes,
}: SequenceActionProps) {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);
  const agentsList = useAppSelector(({ aiVoice }) => aiVoice.listAgents.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (agentsList === undefined) {
      dispatch(lookupAgents({ callDirection: KnAiAgentCallDirectionEnum.Outbound }));
    }
  }, [dispatch, agentsList]);

  const agent = useMemo(() => {
    if (!agentsList || !aiAgentId) {
      return null;
    }
    return agentsList.find((a) => a.agent_id === aiAgentId) || null;
  }, [agentsList, aiAgentId]);

  const handleSelectActionType = (type: StepTemplateType) => {
    onSelectActionType?.(type);
  };

  const delayLabel = useMemo(() => {
    if (!delayDays && !delayHours && !delayMinutes && !delaySeconds) {
      return "";
    }

    let delayLabel = "";

    if (delayDays) {
      if (delayDays === 1) {
        delayLabel = `${delayDays} day`;
      } else {
        delayLabel = `${delayDays} days`;
      }
    }
    if (delayHours) {
      if (delayHours === 1) {
        delayLabel = `${delayLabel} ${delayHours} hour`;
      } else {
        delayLabel = `${delayLabel} ${delayHours} hours`;
      }
    }
    if (delayMinutes) {
      if (delayMinutes === 1) {
        delayLabel = `${delayLabel} ${delayMinutes} minute`;
      } else {
        delayLabel = `${delayLabel} ${delayMinutes} minutes`;
      }
    }
    if (delaySeconds) {
      if (delaySeconds === 1) {
        delayLabel = `${delayLabel} ${delaySeconds} second`;
      } else {
        delayLabel = `${delayLabel} ${delaySeconds} seconds`;
      }
    }

    return delayLabel;
  }, [delayDays, delayHours, delayMinutes, delaySeconds]);

  return (
    <Box sx={{ gap: "8px", display: "flex" }}>
      <Box
        sx={{
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          width: "654px",
          marginLeft: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ background: theme.palette.bg.inverse, padding: "4px 16px", borderRadius: "8px" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "16px",
                color: theme.palette.txt.default,
              }}
            >
              Step {index + 1}
            </Typography>
          </Box>
          {delayLabel ? (
            <Box sx={{ background: theme.palette.bg.inverse, padding: "4px 16px", borderRadius: "8px" }}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: theme.palette.txt.default,
                }}
              >
                Delay: {delayLabel}
              </Typography>
            </Box>
          ) : (
            "No Delay"
          )}
        </Box>

        {actionType ? (
          <SequenceActionContent
            actionType={actionType}
            setIsExpanded={setIsExpanded}
            expanded={isExpanded}
            subject={subjectTemplate}
            content={messageTemplate}
            agent={agent}
          />
        ) : (
          <SequenceActionTypes
            title="Actions"
            hiddenTypes={hiddenTypes}
            canClose={canClose}
            onClose={() => onRemoveDraftAction?.(index)}
            setActionType={handleSelectActionType}
          />
        )}
      </Box>
      {canUpdateSequence && actionType && isExpanded && (
        <SequenceOptions
          delay={{
            delayDays,
            delayHours,
            delayMinutes,
            delaySeconds,
          }}
          onUpdateAction={() => onUpdatedAction?.()}
          onMoveUp={() => onMoveUp?.(id)}
          onMoveDown={() => onMoveDown?.(id)}
          onDuplicateAction={() => onDuplicateAction?.(id)}
          onSetDelay={(delay) => {
            onSetDelay?.(id, delay);
          }}
          onRemoveAction={() => (!id ? onRemoveDraftAction?.(index) : onRemoveAction?.(id))}
        />
      )}
    </Box>
  );
}
