import { ConsentLevel } from "@/common/constants/consent-level";
import { KnContactLeadSourceEnum, KnContactLeadTypeEnum } from "@/types/enums";

export const ProspectLeadSourceOptions = Object.entries(KnContactLeadSourceEnum).map(([key, value]) => ({
  label: key,
  value: value,
}));

export const ProspectLeadTypeOptions = Object.entries(KnContactLeadTypeEnum).map(([key, value]) => ({
  label: key,
  value: value,
}));

export const ProspectConsentLevelOptions = Object.entries(ConsentLevel).map(([key, value]) => ({
  label: key,
  value: value,
}));

const jobTitles = [
  "Account Executive",
  "Account Manager",
  "Accountant",
  "Accountant",
  "Accountant",
  "Accounting Clerk",
  "Accounting Manager",
  "Administrative Assistant",
  "Administrative Assistant",
  "Administrative Assistant",
  "Art Director",
  "Art Director",
  "Art Director",
  "Artificial Intelligence Engineer",
  "Artificial Intelligence Engineer",
  "Brand Ambassador",
  "Brand Ambassador",
  "Brand Ambassador",
  "Brand Manager",
  "Brand Manager",
  "Brand Manager",
  "Brand Strategist",
  "Business Analyst",
  "Business Analyst",
  "Business Analyst",
  "Business Consultant",
  "Business Consultant",
  "Business Development Director",
  "Business Development Director",
  "Business Development Manager",
  "Business Development Manager",
  "Business Development Representative",
  "Business Development Representative",
  "Business Development Representative",
  "Business Development Specialist",
  "Business Development Specialist",
  "Business Manager",
  "Business Manager",
  "Business Manager",
  "Business Operations Manager",
  "CAO - Chief Analytics Officer",
  "CCO - Chief Compliance Officer",
  "CDO - Chief Design Officer",
  "CDO - Chief Digital Officer",
  "CEO - Chief Executive Officer",
  "CFO - Chief financial officer",
  "CHRO - Chief Human Resource Officer",
  "CIO - Chief Information officer",
  "CISO - Chief Information Security Officer",
  "CLO - Chief Learning Officer",
  "CMO - Chief Marketing Officer",
  "COO - Chief Operations Officer",
  "CPO - Chief Product Officer",
  "CSO - Chief Sustainability Officer",
  "CTO - Chief Technology Officer",
  "Chief Administrative Officer",
  "Chief Business Officer",
  "Chief Commercial Officer",
  "Chief Experience Officer",
  "Clinical Nurse Specialist",
  "Clinical Psychologist",
  "Clinical Research Coordinator",
  "Community Manager",
  "Community Manager",
  "Community Manager",
  "Content Creator",
  "Content Creator",
  "Content Manager",
  "Content Manager",
  "Content Marketing Manager",
  "Content Marketing Manager",
  "Content Marketing Manager",
  "Content Strategist",
  "Content Strategist",
  "Content Writer",
  "Content Writer",
  "Copywriter",
  "Corporate Counsel",
  "Corporate Counsel",
  "Corporate Trainer",
  "Corporate Trainer",
  "Corporate Trainer",
  "Creative Director",
  "Customer Experience Manager",
  "Customer Experience Manager",
  "Customer Experience Manager",
  "Customer Experience Specialist",
  "Customer Relationship Manager",
  "Customer Service Manager",
  "Customer Service Representative",
  "Customer Success Manager",
  "Customer Success Manager",
  "Customer Success Manager",
  "Customer Support Specialist",
  "Customer Support Specialist",
  "Data Analyst",
  "Data Analyst",
  "Data Analyst",
  "Data Entry Clerk",
  "Data Scientist",
  "Data Scientist",
  "Database Administrator",
  "Database Administrator",
  "Digital Analyst",
  "Digital Analyst",
  "Digital Content Creator",
  "Digital Content Creator",
  "Digital Content Creator",
  "Digital Designer",
  "Digital Marketing Manager",
  "Digital Marketing Manager",
  "Digital Marketing Manager",
  "Digital Marketing Specialist",
  "Digital Marketing Specialist",
  "Digital Media Specialist",
  "Digital Project Manager",
  "Digital Strategist",
  "Digital Strategist",
  "Digital Strategist",
  "Educational Consultant",
  "Environmental Scientist",
  "Environmental Scientist",
  "Environmental Scientist",
  "Event Coordinator",
  "Event Coordinator",
  "Event Coordinator",
  "Event Manager",
  "Event Planner",
  "Event Planner",
  "Executive Assistant",
  "Executive Assistant",
  "Executive Assistant",
  "Executive Chef",
  "Executive Chef",
  "Executive Chef",
  "Fashion Designer",
  "Fashion Designer",
  "Fashion Designer",
  "Finance Director",
  "Finance Manager",
  "Finance Manager",
  "Financial Advisor",
  "Financial Advisor",
  "Financial Analyst",
  "Financial Analyst",
  "Financial Analyst",
  "Financial Analyst",
  "Financial Consultant",
  "Financial Consultant",
  "Financial Controller",
  "Financial Controller",
  "Financial Controller",
  "Financial Manager",
  "Financial Manager",
  "Financial Planner",
  "Financial Planner",
  "Financial Planner",
  "Fitness Trainer",
  "Fitness Trainer",
  "Fitness Trainer",
  "Graphic Artist",
  "Graphic Designer",
  "HR Assistant",
  "HR Coordinator",
  "HR Coordinator",
  "HR Generalist",
  "HR Generalist",
  "HR Manager",
  "HR Manager",
  "HR Recruiter",
  "HR Recruiter",
  "HR Specialist",
  "Health Educator",
  "Healthcare Administrator",
  "Healthcare Administrator",
  "Healthcare Administrator",
  "Healthcare Consultant",
  "Healthcare Consultant",
  "Human Resources Manager",
  "IT Director",
  "IT Manager",
  "IT Manager",
  "IT Manager",
  "IT Support Specialist",
  "IT Support Specialist",
  "IT Support Specialist",
  "Insurance Agent",
  "Insurance Agent",
  "Insurance Agent",
  "Interior Designer",
  "Inventory Manager",
  "Investment Advisor",
  "Investment Advisor",
  "Investment Advisor",
  "Investment Analyst",
  "Investment Analyst",
  "Investment Analyst",
  "Investment Banker",
  "Investment Manager",
  "Laboratory Technician",
  "Laboratory Technician",
  "Laboratory Technician",
  "Legal Advisor",
  "Legal Analyst",
  "Legal Analyst",
  "Legal Analyst",
  "Legal Assistant",
  "Legal Assistant",
  "Legal Assistant",
  "Legal Counsel",
  "Legal Counsel",
  "Legal Counsel",
  "Legal Secretary",
  "Logistics Coordinator",
  "Logistics Coordinator",
  "Logistics Coordinator",
  "Market Analyst",
  "Market Analyst",
  "Market Analyst",
  "Market Development Manager",
  "Market Development Manager",
  "Market Development Manager",
  "Market Research Analyst",
  "Market Research Analyst",
  "Market Research Analyst",
  "Market Researcher",
  "Market Researcher",
  "Marketing Analyst",
  "Marketing Coordinator",
  "Marketing Coordinator",
  "Marketing Coordinator",
  "Marketing Director",
  "Marketing Manager",
  "Marketing Specialist",
  "Marketing Specialist",
  "Mechanical Designer",
  "Mechanical Designer",
  "Mechanical Designer",
  "Mechanical Engineer",
  "Mechanical Engineer",
  "Mechanical Engineer",
  "Media Planner",
  "Medical Technologist",
  "Network Engineer",
  "Network Engineer",
  "Network Engineer",
  "Operations Analyst",
  "Operations Analyst",
  "Operations Analyst",
  "Operations Assistant",
  "Operations Assistant",
  "Operations Coordinator",
  "Operations Director",
  "Operations Manager",
  "Operations Manager",
  "Operations Manager",
  "Pharmaceutical Sales Representative",
  "Pharmaceutical Sales Representative",
  "Pharmaceutical Sales Representative",
  "Product Designer",
  "Product Designer",
  "Product Manager",
  "Product Manager",
  "Product Manager",
  "Product Marketing Manager",
  "Product Owner",
  "Project Coordinator",
  "Project Coordinator",
  "Project Coordinator",
  "Project Engineer",
  "Project Engineer",
  "Project Manager",
  "Public Health Analyst",
  "Public Health Analyst",
  "Public Health Educator",
  "Public Health Educator",
  "Public Health Educator",
  "Public Relations Manager",
  "Public Relations Specialist",
  "Public Relations Specialist",
  "Public Relations Specialist",
  "Quality Assurance Manager",
  "Quality Assurance Manager",
  "Quality Assurance Specialist",
  "Quality Assurance Specialist",
  "Quality Assurance Specialist",
  "Quality Control Inspector",
  "Quality Control Inspector",
  "Real Estate Agent",
  "Recruitment Specialist",
  "Recruitment Specialist",
  "Recruitment Specialist",
  "Research Analyst",
  "Research Analyst",
  "Research Scientist",
  "Risk Analyst",
  "Risk Analyst",
  "Risk Analyst",
  "SEO Specialist",
  "SEO Specialist",
  "SEO Specialist",
  "Sales Analyst",
  "Sales Consultant",
  "Sales Coordinator",
  "Sales Coordinator",
  "Sales Director",
  "Sales Engineer",
  "Sales Executive",
  "Sales Executive",
  "Sales Manager",
  "Sales Manager",
  "Sales Manager",
  "Sales Representative",
  "Sales Support Specialist",
  "Sales Support Specialist",
  "Senior Consultant",
  "Senior Consultant",
  "Social Media Coordinator",
  "Social Media Coordinator",
  "Social Media Coordinator",
  "Social Media Manager",
  "Social Worker",
  "Software Architect",
  "Software Architect",
  "Software Architect",
  "Software Developer",
  "Software Developer",
  "Software Developer",
  "Software Engineer",
  "Software Tester",
  "Solutions Architect",
  "Supply Chain Analyst",
  "Supply Chain Manager",
  "Systems Administrator",
  "Systems Analyst",
  "Technical Account Manager",
  "Technical Project Manager",
  "Technical Project Manager",
  "Technical Project Manager",
  "Technical Recruiter",
  "Technical Recruiter",
  "Technical Sales Engineer",
  "Technical Sales Engineer",
  "Technical Support Specialist",
  "Technical Support Specialist",
  "Technical Support Specialist",
  "Technical Writer",
  "Technical Writer",
  "Technical Writer",
  "UI/UX Designer",
  "UX Designer",
  "UX Designer",
  "UX Designer",
  "UX Designer",
  "UX Researcher",
  "UX Researcher",
  "UX Researcher",
  "UX/UI Designer",
  "UX/UI Developer",
  "UX/UI Developer",
  "UX/UI Developer",
  "Web Developer",
  "Web Developer",
  "Web Developer",
];

export const ProspectJobTitleOptions = jobTitles.map((title) => ({
  label: title,
  value: title,
}));

const ProspectAddressValues = [
  { city: "New York City", state: "NY", country: "US", postal_code: "" },
  { city: "Los Angeles", state: "CA", country: "US", postal_code: "" },
  { city: "Chicago", state: "IL", country: "US", postal_code: "" },
  { city: "Houston", state: "TX", country: "US", postal_code: "" },
  { city: "Phoenix", state: "AZ", country: "US", postal_code: "" },
  { city: "Philadelphia", state: "PA", country: "US", postal_code: "" },
  { city: "San Antonio", state: "TX", country: "US", postal_code: "" },
  { city: "San Diego", state: "CA", country: "US", postal_code: "" },
  { city: "Dallas", state: "TX", country: "US", postal_code: "" },
  { city: "San Jose", state: "CA", country: "US", postal_code: "" },
  { city: "Austin", state: "TX", country: "US", postal_code: "" },
  { city: "Jacksonville", state: "FL", country: "US", postal_code: "" },
  { city: "San Francisco", state: "CA", country: "US", postal_code: "" },
  { city: "Indianapolis", state: "IN", country: "US", postal_code: "" },
  { city: "Columbus", state: "OH", country: "US", postal_code: "" },
  { city: "Fort Worth", state: "TX", country: "US", postal_code: "" },
  { city: "Charlotte", state: "NC", country: "US", postal_code: "" },
  { city: "Seattle", state: "WA", country: "US", postal_code: "" },
  { city: "Denver", state: "CO", country: "US", postal_code: "" },
  { city: "Washington", state: "DC", country: "US", postal_code: "" },
  { city: "Boston", state: "MA", country: "US", postal_code: "" },
  { city: "Detroit", state: "MI", country: "US", postal_code: "" },
  { city: "Nashville", state: "TN", country: "US", postal_code: "" },
  { city: "Memphis", state: "TN", country: "US", postal_code: "" },
  { city: "Portland", state: "OR", country: "US", postal_code: "" },
  { city: "Oklahoma City", state: "OK", country: "US", postal_code: "" },
  { city: "Las Vegas", state: "NV", country: "US", postal_code: "" },
  { city: "Louisville", state: "KY", country: "US", postal_code: "" },
  { city: "Baltimore", state: "MD", country: "US", postal_code: "" },
  { city: "Milwaukee", state: "WI", country: "US", postal_code: "" },
  { city: "Albuquerque", state: "NM", country: "US", postal_code: "" },
  { city: "Tucson", state: "AZ", country: "US", postal_code: "" },
  { city: "Fresno", state: "CA", country: "US", postal_code: "" },
  { city: "Sacramento", state: "CA", country: "US", postal_code: "" },
  { city: "Kansas City", state: "MO", country: "US", postal_code: "" },
  { city: "Long Beach", state: "CA", country: "US", postal_code: "" },
  { city: "Mesa", state: "AZ", country: "US", postal_code: "" },
  { city: "Atlanta", state: "GA", country: "US", postal_code: "" },
  { city: "Colorado Springs", state: "CO", country: "US", postal_code: "" },
  { city: "Virginia Beach", state: "VA", country: "US", postal_code: "" },
  { city: "Raleigh", state: "NC", country: "US", postal_code: "" },
  { city: "Omaha", state: "NE", country: "US", postal_code: "" },
  { city: "Miami", state: "FL", country: "US", postal_code: "" },
  { city: "Oakland", state: "CA", country: "US", postal_code: "" },
  { city: "Minneapolis", state: "MN", country: "US", postal_code: "" },
  { city: "Tulsa", state: "OK", country: "US", postal_code: "" },
  { city: "Wichita", state: "KS", country: "US", postal_code: "" },
  { city: "New Orleans", state: "LA", country: "US", postal_code: "" },
  { city: "Arlington", state: "TX", country: "US", postal_code: "" },
  { city: "Cleveland", state: "OH", country: "US", postal_code: "" },
  { city: "Bakersfield", state: "CA", country: "US", postal_code: "" },
  { city: "Tampa", state: "FL", country: "US", postal_code: "" },
  { city: "Aurora", state: "CO", country: "US", postal_code: "" },
  { city: "Anaheim", state: "CA", country: "US", postal_code: "" },
  { city: "Santa Ana", state: "CA", country: "US", postal_code: "" },
  { city: "Corpus Christi", state: "TX", country: "US", postal_code: "" },
  { city: "Riverside", state: "CA", country: "US", postal_code: "" },
  { city: "St. Louis", state: "MO", country: "US", postal_code: "" },
  { city: "Lexington", state: "KY", country: "US", postal_code: "" },
  { city: "Pittsburgh", state: "PA", country: "US", postal_code: "" },
  { city: "Stockton", state: "CA", country: "US", postal_code: "" },
  { city: "Cincinnati", state: "OH", country: "US", postal_code: "" },
  { city: "Anchorage", state: "AK", country: "US", postal_code: "" },
  { city: "Henderson", state: "NV", country: "US", postal_code: "" },
  { city: "Greensboro", state: "NC", country: "US", postal_code: "" },
  { city: "Plano", state: "TX", country: "US", postal_code: "" },
  { city: "Newark", state: "NJ", country: "US", postal_code: "" },
  { city: "Lincoln", state: "NE", country: "US", postal_code: "" },
  { city: "Toledo", state: "OH", country: "US", postal_code: "" },
  { city: "Orlando", state: "FL", country: "US", postal_code: "" },
  { city: "Chula Vista", state: "CA", country: "US", postal_code: "" },
  { city: "Irvine", state: "CA", country: "US", postal_code: "" },
  { city: "Fort Wayne", state: "IN", country: "US", postal_code: "" },
  { city: "Jersey City", state: "NJ", country: "US", postal_code: "" },
  { city: "Durham", state: "NC", country: "US", postal_code: "" },
  { city: "St. Petersburg", state: "FL", country: "US", postal_code: "" },
  { city: "Laredo", state: "TX", country: "US", postal_code: "" },
  { city: "Buffalo", state: "NY", country: "US", postal_code: "" },
  { city: "Madison", state: "WI", country: "US", postal_code: "" },
  { city: "Lubbock", state: "TX", country: "US", postal_code: "" },
  { city: "Chandler", state: "AZ", country: "US", postal_code: "" },
  { city: "Scottsdale", state: "AZ", country: "US", postal_code: "" },
  { city: "Glendale", state: "AZ", country: "US", postal_code: "" },
  { city: "Reno", state: "NV", country: "US", postal_code: "" },
  { city: "Norfolk", state: "VA", country: "US", postal_code: "" },
  { city: "Winston-Salem", state: "NC", country: "US", postal_code: "" },
  { city: "North Las Vegas", state: "NV", country: "US", postal_code: "" },
  { city: "Irving", state: "TX", country: "US", postal_code: "" },
  { city: "Chesapeake", state: "VA", country: "US", postal_code: "" },
  { city: "Gilbert", state: "AZ", country: "US", postal_code: "" },
  { city: "Hialeah", state: "FL", country: "US", postal_code: "" },
  { city: "Garland", state: "TX", country: "US", postal_code: "" },
  { city: "Fremont", state: "CA", country: "US", postal_code: "" },
  { city: "Baton Rouge", state: "LA", country: "US", postal_code: "" },
  { city: "Richmond", state: "VA", country: "US", postal_code: "" },
  { city: "Boise", state: "ID", country: "US", postal_code: "" },
  { city: "San Bernardino", state: "CA", country: "US", postal_code: "" },
  { city: "Spokane", state: "WA", country: "US", postal_code: "" },
  { city: "Birmingham", state: "AL", country: "US", postal_code: "" },
  { city: "Modesto", state: "CA", country: "US", postal_code: "" },
  { city: "Des Moines", state: "IA", country: "US", postal_code: "" },
  { city: "Rochester", state: "NY", country: "US", postal_code: "" },
  { city: "Tacoma", state: "WA", country: "US", postal_code: "" },
  { city: "Fontana", state: "CA", country: "US", postal_code: "" },
  { city: "Oxnard", state: "CA", country: "US", postal_code: "" },
  { city: "Moreno Valley", state: "CA", country: "US", postal_code: "" },
  { city: "Fayetteville", state: "NC", country: "US", postal_code: "" },
  { city: "Aurora", state: "IL", country: "US", postal_code: "" },
  { city: "Glendale", state: "CA", country: "US", postal_code: "" },
  { city: "Yonkers", state: "NY", country: "US", postal_code: "" },
  { city: "Huntington Beach", state: "CA", country: "US", postal_code: "" },
  { city: "Montgomery", state: "AL", country: "US", postal_code: "" },
  { city: "Amarillo", state: "TX", country: "US", postal_code: "" },
  { city: "Little Rock", state: "AR", country: "US", postal_code: "" },
  { city: "Akron", state: "OH", country: "US", postal_code: "" },
  { city: "Columbus", state: "GA", country: "US", postal_code: "" },
  { city: "Augusta", state: "GA", country: "US", postal_code: "" },
  { city: "Grand Rapids", state: "MI", country: "US", postal_code: "" },
  { city: "Shreveport", state: "LA", country: "US", postal_code: "" },
  { city: "Huntsville", state: "AL", country: "US", postal_code: "" },
  { city: "Mobile", state: "AL", country: "US", postal_code: "" },
  { city: "Tallahassee", state: "FL", country: "US", postal_code: "" },
  { city: "Grand Prairie", state: "TX", country: "US", postal_code: "" },
  { city: "Overland Park", state: "KS", country: "US", postal_code: "" },
  { city: "Knoxville", state: "TN", country: "US", postal_code: "" },
  { city: "Worcester", state: "MA", country: "US", postal_code: "" },
  { city: "Brownsville", state: "TX", country: "US", postal_code: "" },
  { city: "Newport News", state: "VA", country: "US", postal_code: "" },
  { city: "Santa Clarita", state: "CA", country: "US", postal_code: "" },
  { city: "Port St. Lucie", state: "FL", country: "US", postal_code: "" },
  { city: "Providence", state: "RI", country: "US", postal_code: "" },
  { city: "Fort Lauderdale", state: "FL", country: "US", postal_code: "" },
  { city: "Chattanooga", state: "TN", country: "US", postal_code: "" },
  { city: "Tempe", state: "AZ", country: "US", postal_code: "" },
  { city: "Oceanside", state: "CA", country: "US", postal_code: "" },
  { city: "Garden Grove", state: "CA", country: "US", postal_code: "" },
  { city: "Cape Coral", state: "FL", country: "US", postal_code: "" },
  { city: "Santa Rosa", state: "CA", country: "US", postal_code: "" },
  { city: "Vancouver", state: "WA", country: "US", postal_code: "" },
  { city: "Sioux Falls", state: "SD", country: "US", postal_code: "" },
  { city: "Peoria", state: "AZ", country: "US", postal_code: "" },
  { city: "Ontario", state: "CA", country: "US", postal_code: "" },
  { city: "Jackson", state: "MS", country: "US", postal_code: "" },
  { city: "Elk Grove", state: "CA", country: "US", postal_code: "" },
  { city: "Springfield", state: "MO", country: "US", postal_code: "" },
  { city: "Pembroke Pines", state: "FL", country: "US", postal_code: "" },
  { city: "Salem", state: "OR", country: "US", postal_code: "" },
  { city: "Corona", state: "CA", country: "US", postal_code: "" },
  { city: "Eugene", state: "OR", country: "US", postal_code: "" },
  { city: "McKinney", state: "TX", country: "US", postal_code: "" },
  { city: "Fort Collins", state: "CO", country: "US", postal_code: "" },
  { city: "Lancaster", state: "CA", country: "US", postal_code: "" },
  { city: "Cary", state: "NC", country: "US", postal_code: "" },
  { city: "Palmdale", state: "CA", country: "US", postal_code: "" },
  { city: "Hayward", state: "CA", country: "US", postal_code: "" },
  { city: "Salinas", state: "CA", country: "US", postal_code: "" },
  { city: "Alexandria", state: "VA", country: "US", postal_code: "" },
  { city: "Lakewood", state: "CO", country: "US", postal_code: "" },
  { city: "Springfield", state: "MA", country: "US", postal_code: "" },
  { city: "Pasadena", state: "TX", country: "US", postal_code: "" },
  { city: "Sunnyvale", state: "CA", country: "US", postal_code: "" },
  { city: "Macon", state: "GA", country: "US", postal_code: "" },
  { city: "Pomona", state: "CA", country: "US", postal_code: "" },
  { city: "Hollywood", state: "FL", country: "US", postal_code: "" },
  { city: "Kansas City", state: "KS", country: "US", postal_code: "" },
  { city: "Escondido", state: "CA", country: "US", postal_code: "" },
  { city: "Clarksville", state: "TN", country: "US", postal_code: "" },
  { city: "Joliet", state: "IL", country: "US", postal_code: "" },
  { city: "Rockford", state: "IL", country: "US", postal_code: "" },
  { city: "Torrance", state: "CA", country: "US", postal_code: "" },
  { city: "Naperville", state: "IL", country: "US", postal_code: "" },
  { city: "Paterson", state: "NJ", country: "US", postal_code: "" },
  { city: "Savannah", state: "GA", country: "US", postal_code: "" },
  { city: "Bridgeport", state: "CT", country: "US", postal_code: "" },
  { city: "Mesquite", state: "TX", country: "US", postal_code: "" },
  { city: "Killeen", state: "TX", country: "US", postal_code: "" },
  { city: "Syracuse", state: "NY", country: "US", postal_code: "" },
  { city: "McAllen", state: "TX", country: "US", postal_code: "" },
  { city: "Pasadena", state: "CA", country: "US", postal_code: "" },
  { city: "Bellevue", state: "WA", country: "US", postal_code: "" },
  { city: "Fullerton", state: "CA", country: "US", postal_code: "" },
  { city: "Orange", state: "CA", country: "US", postal_code: "" },
  { city: "Dayton", state: "OH", country: "US", postal_code: "" },
  { city: "Miramar", state: "FL", country: "US", postal_code: "" },
  { city: "Thornton", state: "CO", country: "US", postal_code: "" },
  { city: "West Valley City", state: "UT", country: "US", postal_code: "" },
  { city: "Olathe", state: "KS", country: "US", postal_code: "" },
  { city: "Hampton", state: "VA", country: "US", postal_code: "" },
  { city: "Warren", state: "MI", country: "US", postal_code: "" },
  { city: "Midland", state: "TX", country: "US", postal_code: "" },
  { city: "Waco", state: "TX", country: "US", postal_code: "" },
  { city: "Charleston", state: "SC", country: "US", postal_code: "" },
  { city: "Denton", state: "TX", country: "US", postal_code: "" },
  { city: "Victorville", state: "CA", country: "US", postal_code: "" },
  { city: "Fairfield", state: "CA", country: "US", postal_code: "" },
  { city: "El Monte", state: "CA", country: "US", postal_code: "" },
  { city: "Buffalo", state: "NY", country: "US", postal_code: "" },
  { city: "South Bend", state: "IN", country: "US", postal_code: "" },
  { city: "Berkeley", state: "CA", country: "US", postal_code: "" },
  { city: "Norman", state: "OK", country: "US", postal_code: "" },
  { city: "Fargo", state: "ND", country: "US", postal_code: "" },
  { city: "Columbia", state: "SC", country: "US", postal_code: "" },
  { city: "Boulder", state: "CO", country: "US", postal_code: "" },
  { city: "Broken Arrow", state: "OK", country: "US", postal_code: "" },
  { city: "Palm Bay", state: "FL", country: "US", postal_code: "" },
  { city: "Sandy Springs", state: "GA", country: "US", postal_code: "" },
  { city: "Roanoke", state: "VA", country: "US", postal_code: "" },
  { city: "Billings", state: "MT", country: "US", postal_code: "" },
  { city: "Surprise", state: "AZ", country: "US", postal_code: "" },
  { city: "Flint", state: "MI", country: "US", postal_code: "" },
  { city: "Gresham", state: "OR", country: "US", postal_code: "" },
  { city: "Antioch", state: "CA", country: "US", postal_code: "" },
  { city: "Erie", state: "PA", country: "US", postal_code: "" },
  { city: "Daly City", state: "CA", country: "US", postal_code: "" },
  { city: "Centennial", state: "CO", country: "US", postal_code: "" },
  { city: "Temecula", state: "CA", country: "US", postal_code: "" },
  { city: "Inglewood", state: "CA", country: "US", postal_code: "" },
  { city: "West Palm Beach", state: "FL", country: "US", postal_code: "" },
  { city: "Clovis", state: "CA", country: "US", postal_code: "" },
  { city: "Waterbury", state: "CT", country: "US", postal_code: "" },
  { city: "Cambridge", state: "MA", country: "US", postal_code: "" },
  { city: "Everett", state: "WA", country: "US", postal_code: "" },
  { city: "Lakeland", state: "FL", country: "US", postal_code: "" },
  { city: "Pompano Beach", state: "FL", country: "US", postal_code: "" },
  { city: "Garden Grove", state: "CA", country: "US", postal_code: "" },
  { city: "McKinney", state: "TX", country: "US", postal_code: "" },
  { city: "Paterson", state: "NJ", country: "US", postal_code: "" },
  { city: "Roseville", state: "CA", country: "US", postal_code: "" },
  { city: "Carson", state: "CA", country: "US", postal_code: "" },
  { city: "Santa Maria", state: "CA", country: "US", postal_code: "" },
  { city: "El Cajon", state: "CA", country: "US", postal_code: "" },
  { city: "San Mateo", state: "CA", country: "US", postal_code: "" },
  { city: "Lewisville", state: "TX", country: "US", postal_code: "" },
  { city: "Rialto", state: "CA", country: "US", postal_code: "" },
  { city: "Davenport", state: "IA", country: "US", postal_code: "" },
  { city: "Lakewood", state: "CA", country: "US", postal_code: "" },
  { city: "Tuscaloosa", state: "AL", country: "US", postal_code: "" },
  { city: "Sandy", state: "UT", country: "US", postal_code: "" },
  { city: "Mission Viejo", state: "CA", country: "US", postal_code: "" },
  { city: "Livermore", state: "CA", country: "US", postal_code: "" },
  { city: "Youngstown", state: "OH", country: "US", postal_code: "" },
  { city: "Jonesboro", state: "AR", country: "US", postal_code: "" },
  { city: "Bellingham", state: "WA", country: "US", postal_code: "" },
  { city: "Greeley", state: "CO", country: "US", postal_code: "" },
  { city: "Taylorsville", state: "UT", country: "US", postal_code: "" },
  { city: "Lafayette", state: "LA", country: "US", postal_code: "" },
  { city: "Federal Way", state: "WA", country: "US", postal_code: "" },
  { city: "Reading", state: "PA", country: "US", postal_code: "" },
  { city: "Fort Smith", state: "AR", country: "US", postal_code: "" },
  { city: "Newport Beach", state: "CA", country: "US", postal_code: "" },
  { city: "Asheville", state: "NC", country: "US", postal_code: "" },
  { city: "Green Bay", state: "WI", country: "US", postal_code: "" },
  { city: "Albany", state: "NY", country: "US", postal_code: "" },
  { city: "Edinburg", state: "TX", country: "US", postal_code: "" },
  { city: "Beaumont", state: "TX", country: "US", postal_code: "" },
  { city: "Scranton", state: "PA", country: "US", postal_code: "" },
  { city: "Trenton", state: "NJ", country: "US", postal_code: "" },
  { city: "Roswell", state: "GA", country: "US", postal_code: "" },
  { city: "Mission", state: "TX", country: "US", postal_code: "" },
  { city: "Lawrence", state: "KS", country: "US", postal_code: "" },
  { city: "Bellflower", state: "CA", country: "US", postal_code: "" },
  { city: "Plantation", state: "FL", country: "US", postal_code: "" },
  { city: "Fishers", state: "IN", country: "US", postal_code: "" },
  { city: "Muncie", state: "IN", country: "US", postal_code: "" },
  { city: "Lynwood", state: "CA", country: "US", postal_code: "" },
  { city: "Concord", state: "NC", country: "US", postal_code: "" },
  { city: "Livermore", state: "CA", country: "US", postal_code: "" },
  { city: "Moore", state: "OK", country: "US", postal_code: "" },
  { city: "Iowa City", state: "IA", country: "US", postal_code: "" },
  { city: "Schaumburg", state: "IL", country: "US", postal_code: "" },
  { city: "Homestead", state: "FL", country: "US", postal_code: "" },
  { city: "Decatur", state: "IL", country: "US", postal_code: "" },
  { city: "Hemet", state: "CA", country: "US", postal_code: "" },
  { city: "Kalamazoo", state: "MI", country: "US", postal_code: "" },
  { city: "Albany", state: "GA", country: "US", postal_code: "" },
  { city: "Council Bluffs", state: "IA", country: "US", postal_code: "" },
  { city: "Deltona", state: "FL", country: "US", postal_code: "" },
  { city: "Flower Mound", state: "TX", country: "US", postal_code: "" },
  { city: "Palatine", state: "IL", country: "US", postal_code: "" },
  { city: "Jupiter", state: "FL", country: "US", postal_code: "" },
];

export const ProspectAddressOptions = ProspectAddressValues.map((address) => {
  return {
    label: `${address.city}, ${address.state}, ${address.country}`,
    value: address,
  };
});
