import { SequenceTemplateStatusType } from "@/types/sequence";
import { capitalize } from "@/utils/capitalize";
import { Box, Chip, Typography, useTheme } from "@mui/material";

type SequenceStatusProps = {
  status: SequenceTemplateStatusType;
  numRemainingVersions?: number;
};

function formatSequenceStatus(status: SequenceTemplateStatusType) {
  if (status === "PAUSED_IN_TEAM_LEVEL" || status === "PAUSED_IN_TEMPLATE_LEVEL") {
    return "Paused";
  }

  return capitalize(status.replace(/_/g, " "));
}

export default function SequenceStatus({ status, numRemainingVersions = 0 }: SequenceStatusProps) {
  const theme = useTheme();

  const bgColorByStatus: Partial<{ [key in SequenceTemplateStatusType]: string }> = {
    ACTIVE: theme.palette.btnPrimary.default,
  };

  const colorByStatus: Partial<{ [key in SequenceTemplateStatusType]: string }> = {
    ACTIVE: theme.palette.commonColors.text,
  };

  const defaultStyles = {
    bgcolor: bgColorByStatus[status] || theme.palette.midnight.p,
    color: colorByStatus[status] || theme.palette.commonColors.white,
    fontWeight: 500,
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Chip
        label={formatSequenceStatus(status)}
        sx={{
          ...defaultStyles,
        }}
      />
      {numRemainingVersions > 0 && (
        <Typography sx={{ color: theme.palette.commonColors.informative }}>+{numRemainingVersions}</Typography>
      )}
    </Box>
  );
}
