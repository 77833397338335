import { AdminRole, RegularRoles, SuperadminRole } from "@/common/constants/roles";
import { KnTeamUserRoleUserRoleEnum } from "@/services/generated";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";

export const useRoles = () => {
  const { user, ...auth0 } = useAuth0();

  const userRole = useMemo(() => {
    let userRole = user?.team_user_role;

    if (user?.is_impersonation) {
      userRole = user?.impersonated_user?.team_user_role;
    }

    return userRole;
  }, [user?.impersonated_user?.team_user_role, user?.is_impersonation, user?.team_user_role]);

  const userRoles = useMemo(() => {
    const roles = [...RegularRoles];

    if ([KnTeamUserRoleUserRoleEnum.Superadmin, KnTeamUserRoleUserRoleEnum.Admin].includes(userRole)) {
      roles.push(AdminRole);

      if (userRole === KnTeamUserRoleUserRoleEnum.Superadmin) {
        roles.push(SuperadminRole);
      }
    }

    return roles;
  }, [userRole]);

  const canUpdateUsers = useMemo(() => {
    return [
      KnTeamUserRoleUserRoleEnum.Superadmin,
      KnTeamUserRoleUserRoleEnum.Admin,
      KnTeamUserRoleUserRoleEnum.SalesManager,
    ].includes(userRole);
  }, [userRole]);

  const isAdministrator = useMemo(() => {
    return [KnTeamUserRoleUserRoleEnum.Superadmin, KnTeamUserRoleUserRoleEnum.Admin].includes(userRole);
  }, [userRole]);

  return {
    role: userRole,
    userRoles,
    isAdministrator,
    canUpdateUsers,
    auth0: {
      user,
      ...auth0,
    },
  };
};
