import { combineReducers } from "@reduxjs/toolkit";
import getUserReducer from "./user/index";
import teamReducer from "./team/index";
import teamAiVoiceReducer from "@/redux/reducers/settings/team-ai-voice";
import billingReducer from "./billing/index";
import webhookReducer from "./company/crm/webhook";
import brandRegistrationReducer from "./brand-registration/";
import campaignRegistrationReducer from "./campaign-registration";

const settings = combineReducers({
  user: getUserReducer,
  team: teamReducer,
  teamAiVoice: teamAiVoiceReducer,
  billing: billingReducer,
  brandRegistration: brandRegistrationReducer,
  campaignRegistration: campaignRegistrationReducer,
  webhookReducer: webhookReducer,
});

export default settings;
